<script setup lang="ts"></script>

<template>
  <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.9 14.83">
    <path
      d="m12.25,10.99c-.58-.66-1.01-1.48-1.24-2.38-.25-.97-.26-1.99-.39-2.99-.34-2.55-1.72-4.8-4.17-4.74h0s0,0,0,0c0,0,0,0,0,0h0c-2.45-.06-3.83,2.19-4.17,4.74-.13,1-.14,2.02-.39,2.99-.23.9-.66,1.72-1.24,2.38-.27.3-.09.82.29.82h5.51s0,0,0,0h5.51c.38,0,.55-.52.29-.83Z"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
    <path
      d="m4.77,12.8c.26.68.91,1.16,1.68,1.16s1.42-.48,1.68-1.16"
      style="fill: none; stroke: #999; stroke-linecap: round; stroke-linejoin: round"
    />
  </svg>
</template>

<style scoped></style>
